<template>
  <div class="voffice-panelContainer">
    <!-- <div class="voffice-searchContainer">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" class="voffice-userIconSVG">
      <path :fill="color" d="M256,32c61.8,0,112,50.2,112,112s-50.2,112-112,112s-112-50.2-112-112S194.2,32,256,32 M384,352
        c52.9,0,96,43.1,96,96v32H32v-32c0-52.9,43.1-96,96-96c85,0,67.3,16,128,16C316.9,368,298.9,352,384,352 M256,0
        c-79.5,0-144,64.5-144,144s64.5,144,144,144s144-64.5,144-144S335.5,0,256,0z M384,320c-92.4,0-71,16-128,16c-56.8,0-35.7-16-128-16
        C57.3,320,0,377.3,0,448v32c0,17.7,14.3,32,32,32h448c17.7,0,32-14.3,32-32v-32C512,377.3,454.7,320,384,320z"/>
      </svg>

      <input type="text" placeholder="Search" class="voffice-inputSearch" v-model="search" :style="`border-bottom: 1px solid ${color}`"/>

      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" class="voffice-searchIcon">
      <path :fill="color" d="M508.5,481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395,312,416,262.5,416,208C416,93.1,322.9,0,208,0
        S0,93.1,0,208s93.1,208,208,208c54.5,0,104-21,141.1-55.2V371c0,3.2,1.3,6.2,3.5,8.5l129,129c4.7,4.7,12.3,4.7,17,0l9.9-9.9
        C513.2,493.9,513.2,486.3,508.5,481.6z M208,384c-97.3,0-176-78.7-176-176S110.7,32,208,32s176,78.7,176,176S305.3,384,208,384z"/>
      </svg>
    </div> -->
    <template v-if="!showTeams && filteredUsers.length > 0">
      <Users
        v-for="contact in filteredUsers"
        :namespace="namespace"
        :key="contact.uuid"
        :user="contact"
        :beaconSettings="beaconSettings"
      />
    </template>
    <template v-if="showTeams && teams.length > 0">
      <Teams
        :teams="teams"
        :namespace="namespace"
        :color="color"
        :beaconSettings="beaconSettings"
      />
    </template>
    <template
      v-if="
        (!showTeams && filteredUsers.length === 0) ||
        (showTeams && teams.length === 0)
      "
    >
      <div class="voffice-noMsgContainer">
        <span
          class="voffice-noUsrsMsg"
          v-html="$sanitize(checkForUrls(getNoUsersMessage, false))"
        ></span>
      </div>
    </template>
  </div>
</template>
<script>
import Users from "./Users";
import Teams from "./Teams";
export default {
  props: [
    "namespace",
    "color",
    "setReference",
    "users",
    "showTeams",
    "teams",
    "beaconSettings",
  ],
  components: { Users, Teams },
  data() {
    return {
      search: "",
    };
  },
  created() {},
  mounted() {
    this.setReference();
  },
  methods: {
    getShowUser(contact) {
      if (contact.user.connected && !contact.user.isInCall) {
        return true;
      } else if (
        contact.user.connected &&
        contact.user.isInCall &&
        contact.user.allowBeaconWhileInCall == "active"
      ) {
        return true;
      } else if (
        contact.user.connected &&
        contact.user.isInCall &&
        contact.user.allowBeaconWhileInCall !== "active" &&
        this.beaconSettings.beaconInactiveUsers !== "hidden"
      ) {
        return true;
      } else if (
        !contact.user.connected &&
        this.beaconSettings.beaconInactiveUsers !== "hidden"
      ) {
        return true;
      }
      return false;
    },
    checkForUrls(message, newline = true) {
      if (!message) return;
      const linkExpBlacklist =
          /["'>](((https?|ftp|file):\/\/)?[-A-Z0-9+&@'#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])(["']|<\/a>)/gim,
        linkExp =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@'#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
        wwwExp = /(^|[^/]\b)(www\.[^\s<]+(\b|$))/gim,
        hyperlinkBlacklist = [];
      // Search hyperlinks with <a> tag
      message.replace(linkExpBlacklist, (...args) => {
        if (hyperlinkBlacklist.indexOf(args[1]) === -1)
          hyperlinkBlacklist.push(args[1]);
      });
      // Replace hyperlinks ignoring hyperlink blacklist
      return message
        .replace(linkExp, (...args) => {
          if (hyperlinkBlacklist.indexOf(args[1]) === -1) {
            return `${
              newline ? "<br>" : ""
            }<a style="text-decoration: none;" target="_blank" rel="noopener noreferrer nofollow" href="${
              args[1]
            }">${args[1]}</a>${newline ? "<br>" : ""}`;
          }
          return args[0];
        })
        .replace(wwwExp, (...args) => {
          if (hyperlinkBlacklist.indexOf(args[2]) === -1) {
            return `${args[1]}${
              newline ? "<br>" : ""
            }<a style="text-decoration: none;" target="_blank" rel="noopener noreferrer nofollow" href="http://${
              args[2]
            }">${args[2]}</a>${newline ? "<br>" : ""}`;
          }
          return args[0];
        });
    },
  },
  computed: {
    filteredUsers() {
      return this.users.filter(this.getShowUser);
    },
    getNoUsersMessage() {
      if (this.beaconSettings && this.beaconSettings.noUserMessage == "") {
        let language = navigator.language;
        language = language.split("-")[0].toLowerCase();
        switch (language) {
          case "es":
            return "Actualmente no hay empleados en línea. Por favor, inténtelo más tarde.";
          case "en":
            return "There are currently no employees online. Please try again later.";
          case "de":
            return "Derzeit sind keine Mitarbeiter online. Bitte versuchen Sie es später erneut.";
          default:
            return "There are currently no employees online. Please try again later.";
        }
      } else {
        return this.beaconSettings.noUserMessage;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Muli:300,400,500,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

.voffice-noMsgContainer {
  display: table;
  height: calc(100% - 115px);
  text-align: center;
  width: 100%;
  .voffice-noUsrsMsg {
    white-space: break-spaces;
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    font-size: 15px;
    word-break: break-word;
    padding: 0px 12px 0px 12px;
  }
}
.voffice-panelContainer {
  padding: 10px 0px;
  overflow: auto;
  max-height: calc(100% - 90px);
  font-family: Roboto, sans-serif;
  text-align: center;
}
.voffice-searchContainer {
  display: flex;
  padding: 5px 10px;
  position: relative;
}
.voffice-userIconSVG {
  height: 17px;
  position: relative;
  top: 7px;
  margin-right: 5px;
}
.voffice-searchIcon {
  position: absolute;
  top: 8px;
  right: 15px;
  height: 14px;
}
.voffice-inputSearch {
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 5px;
  outline: none;
}
</style>
