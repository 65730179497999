<template>
  <div class="voffice-Footer">
    <div class="voffice-colum">
      <!-- Footer -->
      <img :src="beaconLogo ? beaconLogo : footerIcon" class="voffice-footerLogo"/>
      <span class="voffice-footerTitle"> {{getTranslatedText}} </span>
    </div>
  </div>
</template>
<script>
export default {
  components: { },
  props: ['beaconLogo', 'footerIcon'],
  data () {
    return {}
  },
  methods: {},
  computed:{
    getTranslatedText(){
      let language = navigator.language;
      language = language.split('-')[0].toLowerCase();
      switch(language){
        case 'es':
          return 'vOffice Videoconferencia';
        case 'en':
          return 'vOffice videoconferencing';
        case 'de':
          return 'vOffice Videokonferenzen';
        default:
          return 'vOffice videoconferencing';
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Muli:300,400,500,700);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.voffice-Footer{
  width: calc(100% - 20px) !important;
  height: 45px;
  background-color: white;
  border-top: 1px solid #f2f2f2;
  bottom: 0;
  position: absolute;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  padding: 0px 10px;
  font-family: Roboto, sans-serif;
}
.voffice-colum{
  display: flex;
  width: 100%;
  height: 45px;
  font-family: Roboto, sans-serif;
  align-items: center;
  justify-content: center;
  .voffice-footerLogo{
    height: 26px;
    margin-right: 10px;
  }
  .voffice-footerTitle{
    font-family: Roboto, sans-serif;
    color: black !important;
    font-size: 15px !important;
    font-weight: 300 !important;
  }
}
</style>
