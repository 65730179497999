<template>
  <div class="voffice-userDiv" @mouseover="selectIndex(user.uuid)" @mouseout="ItemIndex = null">
    <img class="voffice-userImg" :src="`${getAvatar}`" />
    <!-- <at-button  icon="icon-users"></at-button> -->
    <!-- <p class="voffice-userInfo">{{ user.user.name }} <br v-if="getUserTeam"><span class="voffice-userTeam" v-if="getUserTeam">{{getUserTeam}}</span></p> -->
    <p class="voffice-userInfo">{{ user.user.name }}</p>
    <button :style="{background: getButtonColor}" class="voffice-callButton" :disabled="!user.user.connected || user.user.activity === 'Out of Office' || user.user.activity === 'No Calls' || (user.user.isInCall && user.user.allowBeaconWhileInCall !== 'active')" @click="handlerUserClick()">
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
      <path :fill="getButtonColor !== 'yellow' ? 'white' : 'black'" d="M0.803,387l23.19,100.7c3.24,14.236,15.91,24.327,30.51,24.3c252.19,0,457.49-204.2,457.49-457.49
        c0.054-14.552-10.006-27.19-24.2-30.4l-100.8-23.3c-14.738-3.399-29.8,4.216-35.8,18.1l-46.5,108.5
        c-5.454,12.791-1.831,27.647,8.9,36.49l53.9,44.1c-34.116,69.365-90.235,125.484-159.6,159.6l-44.1-53.9
        c-8.847-10.733-23.706-14.356-36.5-8.9l-108.39,46.5C5.029,357.255-2.593,372.288,0.803,387z M32.103,380.4l107.39-46l59.5,72.8
        c103.6-48.6,159.7-104.9,208.1-208.1l-72.8-59.5l46-107.39l99.6,23c-0.2,234.49-190.3,424.49-424.79,424.79L32.103,380.4z"/>
      </svg>
    </button>
    <!-- <div class="voffice-userTT" :ref="`vofficeTT-${user.uuid}`" :class="{active: ItemIndex == user.uuid}" :style="{top: getTTTop(user.uuid)}">
      Teams: {{getUserTeam}}
    </div> -->
  </div>
</template>


<script>
export default {
  props: ['user', 'namespace', 'beaconSettings'],
  data () {
    return {
      ItemIndex: null
    }
  },
  methods: {
    selectIndex(Index) {
      this.ItemIndex = Index;
    },
    handlerUserClick(){
      window.open(`${this.namespace}/api/beaconCallInvite/#${this.user.uuid}`,  "_blank")
    },
    getTTTop(uuid){
      const reference = this.$refs[`vofficeTT-${uuid}`];
      if ( reference ){
        const top = reference.clientHeight;
        return `-${top}px`
      }
    }
  },
  computed: {
    getButtonColor(){
      if ( this.user.user.connected && this.user.user.isInCall ){
        if ( this.user.user.allowBeaconWhileInCall == 'active'){
          if ( this.beaconSettings.beaconUsersIcall == 'yellow' ) {
            return '#ffa500'
          }else{
            return this.beaconSettings.beaconUsersIcall;
          }
        }else{
          return this.beaconSettings.beaconInactiveUsers;
        }
      }else if ( this.user.user.connected && !this.user.user.isInCall ){
        return 'green';
      }else if ( !this.user.user.connected && this.beaconSettings.beaconInactiveUsers !== 'hidden'){
        return this.beaconSettings.beaconInactiveUsers;
      }
      return '#6d6d6d';
    },
    drawUser(){
      if ( this.search == ''){
        return true;
      }else if ( this.user.user.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
        return true;
      }else{
        return false;
      }
    },
    getUserTeam(){
      if ( this.user.user.teams && this.user.user.teams.length > 0 ){
        return this.user.user.teams.join(', ')
      }else{
        return false;
      }
    },
    getAvatar(){
      if ( this.user.user.avatar == 'img/default_profile_picture.png'){
        return `${this.namespace}/img/default_profile_picture.png`
      }else{
        return this.user.user.avatar;
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

.voffice-userDiv{
  padding: 10px;
  align-content: center;
  align-items: center;
  font-family: Roboto,sans-serif;
  /* width: calc(100% - 20px); */
  text-align: left;
  display: flex;
  font-size: 14px;
  position: relative;
  cursor: default !important;
}
.voffice-userDiv h4{
  margin: 0px;
  font-family: Roboto, sans-serif;
}
.voffice-userImg{
  height: 35px;
  width: 35px;
  vertical-align: middle;
  margin-right: 5px;
  border-radius: 5px;
}
.voffice-callButton{
  &:disabled{
    // background: #6d6d6d;
    cursor: default !important;
  }
  float: right;
  top: 4px;
  position: relative;
  border: none;
  // background: green;
  /* padding: 6px; */
  width: 35px;
  border-radius: 5px;
  cursor: pointer !important;
  height: 30px;
  text-align: center;
  padding: 7px;
  line-height: normal;
  font-size: unset;

}
.voffice-callButton svg{
  height: 15px;
  /* position: relative;
  top: 2px; */
}
.voffice-hidden{
  display: none;
}
.voffice-userTeam{
  margin-top: 2px;
  color: gray;
  min-width: 0;
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.voffice-userInfo{
  margin: 0px;
  min-width: 0;
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.voffice-userTT{
  position: absolute;
  background: #3f3e3ed6;
  padding: 6px;
  border-radius: 5px;
  color: white;
  top: -23px;
  left: 45px;
  font-weight: normal;
  font-family: 'ROBOTO';
  font-size: 12px;
  opacity: 0;
  max-width: 150px; 
  line-height: 1.6;
}
.voffice-userTT.active{
  opacity: 1;
}
</style>
